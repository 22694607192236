import { FC } from 'react';
import {
  Box,
  Grid,
  GridItem,
  Text,
  Heading,
  Center,
  Divider,
  Button
} from '@chakra-ui/react';
import { Product } from '../';

const Gifts: FC = () => {
  return (
    <Box py='140px'>
      <Center>
        <Heading fontSize='5xl'>Mesa de Regalos</Heading>
      </Center>
      <Center>
        <Divider />
      </Center>

      <Text align='center' mb='2em'>Tu contribución nos ayuda a comenzar nuestra nueva vida juntos, pero tu presencia y consideración son el mejor regalo que podríamos recibir.</Text>


      <Grid templateColumns={{sm: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)'}} gap={6}>
        <GridItem>
          <Product>
            <Heading fontSize='3xl'>Amazon</Heading>
            <Divider />
            <Text align='center'>De verdad apreciamos profundamente tu consideración. Puedes explorar nuestra selección para encontrar el regalo perfecto.</Text>
            <Divider />
            <Text fontSize='2xl'>desde $150</Text>
            <a
              href='https://www.amazon.com.mx/wedding/registry/212HL4YB78PLG'
              target='_blank' rel='noopener noreferrer'
            >
              <Button>
                Ir a Amazon
              </Button>
            </a>
          </Product>
        </GridItem>
        <GridItem>
          <Product>
            <Heading fontSize='3xl'>Liverpool</Heading>
            <Divider />
            <Text align='center'>Tu generosidad significa mucho para nosotros. Enterate de artículos de nuestro agrado para ser parte de nuestros recuerdos de boda.</Text>
            <Divider />
            <Text fontSize='2xl'>desde $300</Text>
            <a
              href='https://mesaderegalos.liverpool.com.mx/milistaderegalos/51392673'
              target='_blank' rel='noopener noreferrer'
            >
              <Button>
                Ir a Liverpool 
              </Button>
            </a>
          </Product>
        </GridItem>
        <GridItem>
          <Product>
            <Heading fontSize='3xl'>Deposito</Heading>
            <Divider />
            <Text align='center'>También puedes realizar un depósito o transferencia a nuesta cuenta bancaria. ¡Cualquier gasto es muy apreciado!</Text>
            <Divider />
            <Text fontSize='2xl'>desde $1</Text>
            <Text
              as='b'
              border='1px'
              borderColor='gray.100'
              p='1em'
            >
              4152 3141 8387 4398
            </Text>
          </Product>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default Gifts;

