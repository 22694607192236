import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = { 
    apiKey: "AIzaSyA3sm5SOdqPdeL55j7kYn-XgYk-y4z9fWU",
    authDomain: "wedding-424f9.firebaseapp.com",
    projectId: "wedding-424f9",
    storageBucket: "wedding-424f9.appspot.com",
    messagingSenderId: "373783348810",
    appId: "1:373783348810:web:2a08e02e0ef51a942008b3"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth();

