import { extendTheme, Heading, Divider } from '@chakra-ui/react'
import '@fontsource/norican';
import '@fontsource-variable/playfair-display';
import '@fontsource-variable/cinzel';

Heading.defaultProps = {
};

Divider.defaultProps = {
  maxW: '4em',
  my: '20px',
  borderColor: 'black'
}

const theme = extendTheme({
  fonts: {
    heading: `'Cinzel Variable', serif`,
    //heading: `'Petit Formal Script', cursive`,
    body: `'Playfair Variable', serif`,
    //body: `'Tangerine', cursive`,
  },
  components: {
    Heading: {
      baseStyle: {
        fontWeight: '400',
        marginX: 'auto',
        width: 'max',
        textAlign: 'center',
        color: 'yellow.600'
      },
    },
    Text: {
      baseStyle: {
  //      fontSize: '3xl'
      }
    },
    Divider: {
      baseStyle: {
        marginX: 'auto',
        width: 'max',
      },
    },
    Button: {
      baseStyle: {
        borderRadius: 0,
        padding: '1.5em',
      },
      defaultProps: {
        variant: 'outline',
      },
    },
    Input: {
      baseStyle: {
        borderRadius: 0,
      },
    },
  },
});

export default theme;
