import { FC, useState } from 'react';

import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../utils/firebase';

import { redirect } from 'react-router-dom';

import {
  Box,
  Input,
  Button,
  FormControl,
  Card,
  Center,
  VStack
} from '@chakra-ui/react';

const Login: FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const tryToLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
    .then(() => alert('Acceso autorizado'))
    .catch(() => alert('Credenciales incorrectas'))
  };

  return (
    <Box>
      <Center h='100vh'>
        <Card p='1em'>
        <FormControl>
          <VStack>
            <Input
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder='Correo'
            />
            <Input
              onChange={e => setPassword(e.target.value)}
              type='password'
              placeholder='Contraseña'
            />
            <Button
              onClick={tryToLogin}
            >
              Acceder
            </Button>
          </VStack>
        </FormControl>
        </Card>
      </Center>
    </Box>
  );
}

export default Login;

