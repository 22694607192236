import { FC, ReactNode } from 'react';
import { Card, VStack } from '@chakra-ui/react';

type Props = {
  children: ReactNode
}

const Product: FC<Props> = ({ children }) => {
  return (
    <Card
      borderWidth='10px'
      borderColor='blue.900'
      p='17px'
      borderRadius='0'
      height='100%'
    >
      <VStack>
        { children }
      </VStack>
    </Card>
  );
}

export default Product;

