import { FC } from 'react';
import {
  Box,
  Text,
  Heading,
  Center,
  Divider,
  Image,
  Grid,
  GridItem
} from '@chakra-ui/react';

const DressCode: FC = () => {
  return (
    <Box py='140px'>
      <Center>
        <Heading>Código de Vestimenta</Heading>
      </Center>
      <Divider />
      <Grid templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}} gap='6'>
        <GridItem>
          <Image
            src='/assets/codigo-hombre.png'
            mx='auto'
            my='1em'
            height='600px'
          />
          <Center>
            <Heading fontSize='3xl'>Hombres</Heading>
          </Center>
          <Divider />
          <Text align='center'>Traje con corbata.</Text>
        </GridItem>
        <GridItem>
          <Image
            src='/assets/codigo-mujer.png'
            mx='auto'
            my='1em'
            height='600px'
          />
          <Center>
            <Heading fontSize='3xl'>Mujeres</Heading>
          </Center>
          <Divider />
          <Text align='center'>Vestido largo.</Text>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default DressCode;

