import { FC, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react'
import {
  Box,
  Center,
  Text,
  Card,
  Heading,
  FormControl,
  VStack,
  Input,
  Divider,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import QRCode from 'react-qr-code';

import { acceptInvitation, refuseInvitation } from '../../utils/api';


const Confirmation: FC = () => {
  const [id, setId] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [kind, setKind] = useState<string>('');
  const [mode, setMode] = useState<string>('confirmation');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [QRInvitationCode, setQRInvitationCode] = useState<string>('');

  const tryToConfirm = async () => {
    if (id === '') {
      alert('Introduce un código de invitación');
      return;
    }

    if (name === '') {
      alert('Introduce tú nombre');
      return;
    }

    try {
      await acceptInvitation(id, name, 'adult');
      setQRInvitationCode(id);
      setId('');
      setName('');
      setKind('');
      onOpen();
    } catch (e: any) {
      alert('Código de invitación invalido');
      setId('');
      console.log(e.Message);
    }

  }

  const tryToRefuse = async () => {
    if (id === '') {
      alert('Introduce un código de invitación');
      return;
    }

    try {
      await refuseInvitation(id, name);
      setId('');
      setName('');
      setKind('');
      alert('Invitación rechazada');
    } catch (e: any) {
      alert('Código de invitación invalido');
      setId('');
      console.log(e.Message);
    }

  }

  return (
    <Box py='140px'>
      <Center>
        <Heading id='confirmar'>¿Tienes un Código de Invitación?</Heading>
      </Center>
      <Divider />

      <Card maxWidth='500px' mx='auto' p='40px'>
        <FormControl as='fieldset'>
          {
            mode === 'confirmation' ? (
              <VStack spacing='4'>
                <Input
                  value={id}
                  placeholder='CÓDIGO DE INVITACIÓN'
                  onChange={e => setId(e.target.value)}
                /> 
                <Input
                  value={name}
                  placeholder='¿CÚAL ES TU NOMBRE?'
                  onChange={e => setName(e.target.value)}
                /> 
                <Button
                  my='1em'
                  onClick={tryToConfirm}
                  colorScheme='blue'
                >
                  CONFIRMAR ASISTENCIA
                </Button>
                <Button
                  variant='link'
                  size='xs'
                  onClick={() => setMode('reject')}
                >
                  o rechazar invitación
                </Button>
              </VStack>
            ) : (
              <VStack spacing='4'>
                <Input
                  value={id}
                  placeholder='CÓDIGO DE INVITACIÓN'
                  onChange={e => setId(e.target.value)}
                /> 
                <Input
                  value={name}
                  placeholder='¿CÚAL ES TU NOMBRE? (OPCIONAL)'
                  onChange={e => setName(e.target.value)}
                /> 
                <Button
                  my='1em'
                  onClick={tryToRefuse}
                  colorScheme='red'
                >
                  RECHAZAR INVITACIÓN
                </Button>
                <Button
                  variant='link'
                  size='xs'
                  onClick={() => setMode('confirmation')}
                >
                  o aceptar invitación
                </Button>
              </VStack>
            )
          }
        </FormControl>
      </Card>

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset='slideInBottom'
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Center>¡Gracias por tu confirmación!</Center>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text align='center'>Es muy especial para nosotros que nos acompañes ❤️. Que Dios los bendiga, hoy y siempre, hasta el final.</Text>
            <Center my='2em'>
              <QRCode value={QRInvitationCode} />
            </Center>
            <Text> Guarda este QR. Será necesario para tu acceso el día del evento. </Text>

          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              ¡OK! 
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </Box>
  );
}

export default Confirmation;

