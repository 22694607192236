import { FC } from 'react';
import { Box, Container } from '@chakra-ui/react';

import {
  Confirmation,
  Location,
  MainPhoto,
  Message,
  Gifts,
  Parents,
  Photos,
  DressCode
} from '../components';

const Home: FC = () => {
  return (
    <Box bg=''>
      <MainPhoto />
      <Box>
        <Container maxW='1039px' padding='17px'>
          <Message/>
        </Container>
      </Box>
      <Box bg='blue.900' color='white'>
        <Container maxW='1039px' padding='17px'>
          <Parents />
        </Container>
      </Box>
      <Box>
        <Container maxW='1039px' padding='17px'>
          <Gifts />
        </Container>
      </Box>
      <Box bg='blue.900' >
        <Container maxW='1039px' padding='17px'>
          <Photos />
        </Container>
      </Box>
      <Box>
        <Container maxW='1039px' padding='17px'>
          <Location />
        </Container>
      </Box>
      <Box>
        <Container maxW='1039px' padding='17px'>
          <DressCode />
        </Container>
      </Box>
      <Box bg='blue.900' color='white'>
        <Container maxW='1039px' padding='17px'>
          <Confirmation />
        </Container>
      </Box>
    </Box>
  );
}

export default Home;

