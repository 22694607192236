import { FC } from 'react';
import { Box, Center, Grid, GridItem, Image } from '@chakra-ui/react';

const Photos: FC = () => {
  return (
    <Box py='140px'>
      <Center>
        <Grid templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}>
          <GridItem p='1em'>
            <Image
              src='/assets/hands.png'
              border='2px'
              borderColor='yellow.600'
              height='600px'
            />
          </GridItem>
          <GridItem p='1em'>
            <Image
              src='/assets/linda.png'
              border='2px'
              borderColor='yellow.600'
              height='600px'
            />
          </GridItem>
        </Grid>
      </Center>
    </Box>
  );
}

export default Photos;

