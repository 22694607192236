import { FC } from 'react';
import {
  Box,
  Text,
  Heading,
  Center,
  Divider,
  Image,
  Grid,
  GridItem
} from '@chakra-ui/react';

const Parents: FC = () => {
  return (
    <Box py='140px'>
      <Grid templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}} gap='6'>
        <GridItem>
          <Image
            src='/assets/parents.svg'
            boxSize='38px'
            mx='auto'
            my='1em'
          />
          <Center>
            <Heading fontSize='3xl'>Papás de la Novia</Heading>
          </Center>
          <Divider borderColor='white' />

          <Text align='center'>Hermelinda Torres López</Text>
          <Text align='center'>Arturo Sierra Aleman</Text>
        </GridItem>
        <GridItem>
          <Image
            src='/assets/parents.svg'
            boxSize='38px'
            mx='auto'
            my='1em'
          />
          <Center>
            <Heading fontSize='3xl'>Papás del Novio</Heading>
          </Center>
          <Divider borderColor='white' />

          <Text align='center'>Yolanda Muñoz Muñoz</Text>
          <Text align='center'>Esthela Martinez Negrete &#x271D;</Text>
          <Text align='center'>Candelario Javier Navarro Jímenez</Text>
        </GridItem>
        <GridItem>
          <Image
            src='/assets/hands-pray.svg'
            boxSize='38px'
            mx='auto'
            my='1em'
          />
          <Center>
            <Heading fontSize='3xl'>Padrinos de Velación</Heading>
          </Center>
          <Divider borderColor='white' />

          <Text align='center'>Martha Manuela Sierra Alemán</Text>
          <Text align='center'>José Ramón Plascencia Huerta</Text>
        </GridItem>
        <GridItem>
          <Image
            src='/assets/rings.svg'
            boxSize='38px'
            mx='auto'
            my='1em'
          />
          <Center>
            <Heading fontSize='3xl'>Padrinos de Anillos</Heading>
          </Center>
          <Divider borderColor='white' />

          <Text align='center'>María de los Ángeles López Reyes</Text>
          <Text align='center'>Jesús Moreno Muñoz</Text>
        </GridItem>
        <GridItem
          colSpan={{ sm: 1, md: 2 }}
        >
          <Image
            src='/assets/coins.svg'
            boxSize='38px'
            mx='auto'
            my='1em'
          />
          <Center>
            <Heading fontSize='3xl'>Padrinos de Arras</Heading>
          </Center>
          <Divider borderColor='white' />

          <Text align='center'>María Estela Estefania Navarro Muñoz</Text>
          <Text align='center'>Christian Tadeo Lira Rodriguez</Text>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default Parents;

