import { db } from './firebase';
import {
    collection,
    setDoc,
    query,
    getDocs,
    deleteDoc,
    doc,
    updateDoc,
} from 'firebase/firestore';
import { Invitation, InvitationParameters } from './types';

// CRUD invitations

const getInvitations = async () => { 
    const q = query(collection(db, 'invitations'));
    const snapshot = await getDocs(q);

    let myInvitations: Invitation[] = [];
    snapshot.forEach(doc => {
        myInvitations = [
            ...myInvitations,
            {
                id: doc.id,
                ...doc.data()
            }
        ];
    });

    return myInvitations;
}

const createInvitation = async (note?: string) => {
    const id: string = Math.random().toString(16).substr(2, 6).toUpperCase();
    await setDoc(doc(db, 'invitations', id), {
        status: 'not sent',
        name: '',
        note: note !== undefined ? note : '',
        kind: '',
    });
}

const createSeveralInvitations = async (n: Number, note: string) => {
    for (let i = 1; i <= n; i++)
        await createInvitation(note);
}

const deleteInvitation = async (id: string) => {
    await deleteDoc(doc(db, 'invitations', id));
}

const updateInvitation = async (id: string, invitation: InvitationParameters) => {
    await updateDoc(doc(db, 'invitations', id), { ...invitation });
}

// Special Functions

const acceptInvitation = async (id: string, name: string, kind: string) => {
    await updateInvitation(id, {
        name,
        kind,
        status: 'accepted'
    });
}

const refuseInvitation = async (id: string, name: string) => {
    await updateInvitation(id, {
        name,
        status: 'refused'
    });
}

export {
    createInvitation,
    createSeveralInvitations,
    getInvitations,
    acceptInvitation,
    deleteInvitation,
    updateInvitation,
    refuseInvitation,
};

