import { FC } from 'react';
import { Box }  from '@chakra-ui/react';
import Countdown from 'react-countdown';

const DateCountdown: FC = () => {
  return (
    <Box
      fontSize='4xl'
    >
      <Countdown
        date={new Date(2024, 5, 8, 14, 30)}
      />
    </Box>
  );
}

export default DateCountdown;

