import { FC } from 'react';
import { Center, Text, Image, Button, VStack } from '@chakra-ui/react';
import { DateCountdown } from '..';

const MainPhoto: FC = () => {
  return (
    <Center
      w='100%'
      h='100vh'
      bg='blue.700'
      bgImage="/assets/main-photo.jpg"
      bgSize='cover'
      bgPos='50% 55%'
      pos='relative'
      bgRepeat='no-repeat'
      color='white'
    >
      <VStack>
        <Image
          src='/assets/logo.png'
          boxSize='250px'
        />
        <Text
          fontSize={{ base: '50px', md: '90px' }}
          style={{
            fontFamily: 'Norican'
          }}
        >¡Nos Casamos!</Text>
        <Text>#SELOGRÓL&C</Text>
        <DateCountdown />
        <a href='#confirmar'>
          <Button color='white'>Confirma tu asistencia</Button>
        </a>
      </VStack>
    </Center>
  );
}

export default MainPhoto;

