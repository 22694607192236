import { FC, useState, useEffect } from 'react';
import {
  createInvitation,
  createSeveralInvitations,
  deleteInvitation,
  updateInvitation,
} from '../utils/api';
import { Invitation } from '../utils/types'
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Card,
  Button,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  IconButton,
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon, EmailIcon } from '@chakra-ui/icons';

import { db } from '../utils/firebase';
import {
  collection,
  query,
  onSnapshot,
} from 'firebase/firestore';

const Admin: FC = () => {
  const [invitations, setInvitations] = useState<any[]>([])

  useEffect(() => {
    const q = query(collection(db, 'invitations'));
    onSnapshot(q, (querySnapshot) => {
      let myInvitations: Invitation[] = [];
      querySnapshot.forEach((doc) => {
        myInvitations.push({
          id: doc.id,
          ...doc.data()
        });
      });
      myInvitations.sort((a, b) => {
        if (typeof(a.note) === 'undefined') return 1;
        if (typeof(b.note) === 'undefined') return -1;
        if (a.note === '') return 1;
        if (b.note === '') return -1;
        if (a.note < b.note) return -1;
        if (a.note > b.note) return 1;
        return 0;
      });
      setInvitations(myInvitations);
    });
  }, []);

  const tryDelete = async (id: string) => {
    try {
      await deleteInvitation(id);
    } catch {
      alert('Error');
    }
  }

  const editNote = async (id: string) => {
    try {
      const inputNote = prompt('Nota');
      let newNote: string;

      if (inputNote === null) newNote = '';
      else newNote = inputNote;

      await updateInvitation(id, { note: newNote });
    } catch {
      alert('Error');
    }
  }

  const markAsSent = async (id: string) => {
    try {
      await updateInvitation(id, { status: 'sent' });
    } catch {
      alert('Error');
    }
  }

  const totalStat = () => invitations.length;
  const sentStat = () => invitations.filter(i => i.status === 'sent').length;
  const notSentStat = () => invitations.filter(i => i.status === 'not sent').length;
  const acceptedStat = () => invitations.filter(i => i.status === 'accepted').length;
  const refusedStat = () => invitations.filter(i => i.status === 'refused').length;
  const arrivedStat = () => invitations.filter(i => i.status === 'arrived').length;

  const formatKind = (kind: string) => {
    if (kind === 'child') return '👶';
    if (kind === 'adult') return '👨';
    return '';
  }

  const formatStatus = (status: string) => {
    if (status === 'sent') return '🚀';
    if (status === 'not sent') return '✉️';
    if (status === 'accepted') return '👍';
    if (status === 'refused') return '❌';
    if (status === 'arrived') return '✅';
    return 'Indefinido';
  }

  return (
    <Box p='17px'>
      <Card
        p='17px'
        mb='30px'
      >
        <StatGroup>
          <Stat>
            <StatLabel>Totales</StatLabel>
            <StatNumber>{ totalStat() - refusedStat() }</StatNumber>
          </Stat>

          <Stat>
            <StatLabel>Enviadas</StatLabel>
            <StatNumber>{ sentStat() } /  { sentStat() + notSentStat() }</StatNumber>
          </Stat>

          <Stat>
            <StatLabel>Aceptadas</StatLabel>
            <StatNumber>{ acceptedStat() } / { sentStat() + acceptedStat() }</StatNumber>
          </Stat>

          <Stat>
            <StatLabel>Rechazadas</StatLabel>
            <StatNumber>{ refusedStat() } / { sentStat() + refusedStat() }</StatNumber>
          </Stat>

          <Stat>
            <StatLabel>Dentro</StatLabel>
            <StatNumber>{ arrivedStat() } / { acceptedStat() }</StatNumber>
          </Stat>
        </StatGroup>
      </Card>

      <Button
        onClick={() => {
          createInvitation();
        }}
      > Crear invitación
      </Button>

      <Button
        onClick={() => {
          const n = prompt('¿Cuantas?');
          const note = prompt('Nota');
          createSeveralInvitations(n !== null ? parseInt(n) : 0, note !== null ? note : '');
        }}
      > Crear multiples invitaciones
      </Button>

      <Card
        my='30px'
      >
        <Table >
          <Thead>
            <Tr>
              <Th>Código</Th>
              <Th>Nombre</Th>
              <Th>Tipo</Th>
              <Th>Estado</Th>
              <Th>Notas</Th>
              <Th>-</Th>
            </Tr>
          </Thead>
          <Tbody>
            {
              invitations.map(invitation => (
                <Tr key={invitation.id}>
                  <Td>{invitation.id}</Td>
                  <Td>{invitation.name}</Td>
                  <Td>{formatKind(invitation.kind)}</Td>
                  <Td>{formatStatus(invitation.status)}</Td>
                  <Td>{invitation.note}</Td>
                  <Td>
                    <IconButton
                      onClick={() => markAsSent(invitation.id)}
                      colorScheme='blue'
                      icon={<EmailIcon />}
                      aria-label='Marcar invitación como enviada'
                      isDisabled={invitation.status !== 'not sent'}
                    />
                    <IconButton
                      mx='10px'
                      onClick={() => editNote(invitation.id)}
                      colorScheme='green'
                      icon={<EditIcon />}
                      aria-label='Editar nota de la invitación'
                    />
                    <IconButton
                      onClick={() => tryDelete(invitation.id)}
                      colorScheme='red'
                      icon={<DeleteIcon />}
                      aria-label='Eliminar invitación'
                    />
                  </Td>
                </Tr>
              ))
            }
          </Tbody>
        </Table>
      </Card>
    </Box>
  );
}

export default Admin;

