import { FC } from 'react';
import { Box, Divider, Text, Center, Heading } from '@chakra-ui/react';

const Location: FC = () => {
  return (
    <Box py='140px'>
      <Box py='2em'>
        <Center>
          <Heading>Ceremonia</Heading>
        </Center>
        <Divider />
        <Text align='center'>
          ¡Bienvenidos a nuestro día especial! La ceremonia se llevará a cabo el día <b>8 de junio</b> a la hora <b>2:30 pm</b> en <b>Parroquia del Señor de la Salud, Ignacio Zaragoza 812-822, Cuauhtemoc, 36310 San Francisco del Rincón, Gto</b>. Estamos emocionados de compartir este momento con ustedes y esperamos que disfruten de presenciar este momento único en nuestras vidas.
        </Text>
      </Box>

      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.06965047271!2d-101.86296621563895!3d21.029898937785536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842be847049380ab%3A0xbeb113768e2ff4d6!2sPARROQUIA%20DEL%20SENOR%20DE%20LA%20SALUD!5e0!3m2!1sen!2smx!4v1711428547343!5m2!1sen!2smx" width="100%" height="450" title='Ceremonia'></iframe>

      <Box height='140px'></Box>

      <Box py='2em'>
        <Center>
          <Heading>Recepción</Heading>
        </Center>
        <Divider />
        <Text align='center'>
          ¡La fiesta continúa a partir de las <b>4:00 pm</b> en <b>Salón Miramar, Sajonia 119, 36406 Purísima de Bustos, Gto.!</b> Únanse a nosotros para celebrar y crear recuerdos inolvidables en este lugar especial. Los esperamos en este verdadera celebración llena de amor y alegría.
        </Text>
      </Box>

      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14897.199319197065!2d-101.8843949!3d21.0206858!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842be95a31cc4ff9%3A0xe8befe0c4c133f3c!2sSal%C3%B3n%20Miramar!5e0!3m2!1ses-419!2smx!4v1711428439012!5m2!1ses-419!2smx" width="100%" height="450" title='Recepción'></iframe>
    </Box>
  );
}

export default Location;

