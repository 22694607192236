import { FC } from 'react';
import {
  Box,
  Text,
  Heading,
  Container,
  Center,
  Divider,
  Image
} from '@chakra-ui/react';

const Message: FC = () => {
  return (
    <Box py='140px'>
      <Image
        src='/assets/wedding.svg'
        boxSize='58px'
        mx='auto'
        my='2em'
      />
      <Center>
        <Heading fontSize='6xl'>Nos Casamos</Heading>
      </Center>
      <Divider />

      <Container maxW='lg'>
        <Text fontSize='lg' align='center'>
          Tomamos la decisión más importante en nuestras vidas: pasar el resto de nuestros días juntos, apoyándonos mutuamente y siendo pilar el uno para el otro.<br /><br />El amor ha madurado en nosotros. Ha sido un proceso maravilloso y especial. Así que decidimos iniciar y respetar este sacramento del matrimonio en esta etapa de nuestras vidas. Somos fuertes, dedicados, amorosos y de carácter firme. También agradecemos los retos y circunstancias, buenas o malas, que nos ha presentado la vida, y así darnos cuenta que juntos somos un gran equipo. Podemos construir y lograr lo que nos propongamos. Solo podemos decir que en nuestras vidas, en este momento, se encuentran plenas y estamos listos para compartir el resto de nuestra vida juntos.<br /><br />

          ...¡y que mejor que festejarlo con ustedes!<br /><br />

          <Text fontSize='2xl' style={{ fontFamily: 'Norican' }}>&#8212;Linda y César</Text>
        </Text>
      </Container>
    </Box>
  );
}

export default Message;

